html {
  height: 100%;

  body {
    margin: 0px;
    font-family: sans-serif;
    height: 100%;

    #root {
      height: 100%;
      
      .app {
        height: 100%;
      }
    }
  }
}

